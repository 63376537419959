import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/pages/cooking.styles';

import ReactHtmlParser from 'react-html-parser';

import { breakpoints, colors, fonts } from '../style-utilities/variables';
import PagesHero from '../components/PagesHero';

import placeHolderImage from '../images/placeholder.png';

const ProductCard = styled.div`
  display: inline-block;
  width: 280px;
  margin: 0 20px;
  .image-card {
    display: flex;
    align-items: center;
    @media (min-width: 960px) {
      min-height: 500px;
    }
  }
  .image-card img {
    width: 100%;
  }
  .product-info {
    text-align: center;
  }
`;

const Div = styled.div`
  .hero-section {
    background: ${props => `url(${props.bgImage}) no-repeat 70% 100%`};
    background-size: cover;
    justify-content: left;
    @media (max-width: ${breakpoints.tablet}) {
      background-position: 15% 100%;
    }
  }
  .Hero {
    padding: 18rem 0 12rem 10rem;
    width: 60%;
    @media (max-width: ${breakpoints.desktop}) {
      padding: 5rem 0 8rem 2rem;
    }
    @media (max-width: ${breakpoints.tablet}) {
      width: 90%;
    }
  }
  .Hero h1,
  .Hero h2,
  .Hero p {
    display: block;
    color: #ffffff;
  }
  .Hero h1 {
    margin-bottom: 5px;
  }
  .Hero h2 {
    margin-top: 0;
    font-family: Gotham;
  }
  .hero-overview {
    line-height: 1.5em;
  }
  .opacity {
    padding: 20px;
  }
  hr {
    width: 30%;
    margin: 40px auto 80px;
  }
  .OurStory {
  }
  .faqs {
    width: 80%;
    margin: 0 auto 80px;
    #faqs-title {
      margin-bottom: 35px;
    }
    .faq-button {
      text-align: center;
      margin-top: 20px;
    }
  }
  .Carousel {
    padding: 0 40px;
  }
  .related-products {
    text-align: center;
    margin-top: 20px;
    .selected-products {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    h3 {
      margin: 0;
    }
    p {
      color: ${colors.black};
    }
    .product-text {
      font-size: 16px;
    }
    .related-prod-link {
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
      padding: 12px 0 6px 0;
      display: block;
      min-height: 174px;
      color: ${colors.primary};
      margin: 0 auto;
      h3 {
        font-family: ${fonts.primary};
      }
    }
    @media (max-width: ${breakpoints.tablet}) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .related-footer {
    text-align: center;
    margin-top: 25px;
  }
  .brand-statement {
    margin: 60px auto;
  }
  @media (max-width: ${breakpoints.tablet}) {
    .Carousel {
      padding: 0;
    }
  }

  .microwave p:first-child {
    margin-top: 0;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columnRatio || '40% 60%'};
  margin: 80px;

  .story {
    display: inherit;
    align-content: center;
    padding: 0 20px;
  }
  .imgWrapper {
    background: silver no-repeat;
    background-size: cover;
    min-height: 300px;
  }
  img {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.tablet}) {
    display: block;
    flex-direction: column-reverse;
    margin: 10px 0;
  }
`;

class Cooking extends Component {
  state = {
    activeAcc: 1
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== 'undefined'
    });
    if (this.state.isBrowser) {
      window.addEventListener('resize', this.triggerUpdate);
    }
  }

  triggerUpdate = () => {
    this.forceUpdate();
  };

  accordionChangeHandler = clickedAcc => {
    this.setState({ activeAcc: clickedAcc });
  };

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.triggerUpdate);
    }
  }

  render() {
    const cooking_page = this.props.data.wordpressPage;
    //Hero Image for Cooking Page
    const heroImage =
      this.props.data.wordpressPage.acf.hero_image === null
        ? placeHolderImage
        : this.props.data.wordpressPage.acf.hero_image.source_url;

    /**
     * --------------------------------------------------------------
     */
    return (
      <Div bgImage={heroImage}>
        <Helmet>
          <title>
            {this.props.data.wordpressPage.yoast_head_json.title}
          </title>
          <meta
            name="description"
            content={
              this.props.data.wordpressPage.yoast_head_json.description
            }
          />
        </Helmet>
        <PagesHero className="hero-section">
          <div className="InnerBox">
            <div className="title">
              <h1>{ReactHtmlParser(cooking_page.acf.headline)}</h1>
              <h3>{ReactHtmlParser(cooking_page.acf.sub_headline)}</h3>
            </div>
          </div>
        </PagesHero>
        <div
          style={{ paddingBottom: '40px', paddingTop: '40px' }}
          className="page-container"
        >
          {ReactHtmlParser(cooking_page.acf.overview_section)}
        </div>
      </Div>
    );
  }
}

export default Cooking;

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "cooking" }) {
      slug
      content
      title
      yoast_head_json {
        title
        description
      }
      acf {
        sub_headline
        overview_section
        hero_image {
          source_url
        }
        hero_content
        headline
      }
    }
  }
`;
